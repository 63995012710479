import $ from 'jquery';

export default function initScrollTo(closeNavCallback) {
    document.querySelectorAll('a[href^="#"]').forEach(anchor => {
        anchor.addEventListener('click', function (e) {
            const targetId = this.getAttribute('href'); // Get the href value

            // Ignore if href is "#" or invalid
            if (targetId === "#" || !targetId || targetId === "#!") {
                e.preventDefault();
                return;
            }

            const targetElement = document.querySelector(targetId); // Select the target element
            if (targetElement) {
                e.preventDefault();

                if (typeof closeNavCallback === 'function') {
                    // Close the navigation menu and scroll after it's closed
                    closeNavCallback(() => {
                        targetElement.scrollIntoView({
                            behavior: 'smooth'
                        });
                    });
                } else {
                    // If no callback is provided, just scroll
                    targetElement.scrollIntoView({
                        behavior: 'smooth'
                    });
                }
            }
        });
    });

    window.addEventListener("DOMContentLoaded", function () {
        const pageHash = window.location.hash.toString().split('#')[1];

        if (pageHash) {
            const targetElement = document.getElementById(pageHash);
            if (targetElement) {
                targetElement.scrollIntoView({
                    behavior: 'smooth'
                });
            }
        }
    }, false);
}