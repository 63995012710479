import $ from 'jquery';

const $doc = $(document);

export default function initCapsuleHeadings() {
    $doc.ready(function () {
        $(".basic-text--capsule-headings h3").each(function (index) {
            if (index % 8 === 0) {
                $(this).addClass("highlight-teal");
            }
            if ((index + 1) % 8 === 2) {
                $(this).addClass("highlight-pink");
            }
            if ((index + 1) % 8 === 3) {
                $(this).addClass("highlight-blue-gray");
            }
            if ((index + 1) % 8 === 4) {
                $(this).addClass("highlight-orange");
            }
            if ((index + 1) % 8 === 5) {
                $(this).addClass("highlight-green");
            }
            if ((index + 1) % 8 === 6) {
                $(this).addClass("highlight-yellow");
            }
            if ((index + 1) % 8 === 7) {
                $(this).addClass("highlight-magenta");
            }
            if ((index + 1) % 8 === 0) {
                $(this).addClass("highlight-red");
            }
        });
    });
}