import $ from 'jquery';
import initScrollTo from './scroll-to';
import initSectionObserver from './observer';

const $doc = $(document);
const $reportNav = $('.js-report-nav');
const $menuTrigger = $('.js-menu-trigger');

function closeReportNav(scrollCallback) {
    $reportNav.removeClass('is-active');
    $menuTrigger.removeClass('is-active');
    $menuTrigger.attr('aria-expanded', 'false');

    const body = document.body;
    const scrollY = body.style.top;
    body.style.position = '';
    body.style.width = '';
    body.style.top = '';

    // Restore scroll position and invoke the callback after closing the nav
    window.scrollTo(0, parseInt(scrollY || '0') * -1);

    if (typeof scrollCallback === 'function') {
        setTimeout(scrollCallback, 300); // Ensure scroll starts after menu closes
    }
}

export default function initReportNav() {
    initScrollTo(closeReportNav); // Pass the close function to initScrollTo

    // Initialize section observer
    const cleanupObserver = initSectionObserver({
        sectionSelector: '.annual-report__block[id]', // Select sections with "annual-report__block" class and a non-empty id
        onEnter: (section) => {
            const id = section.id;
            $(`.menu-item a[href="#${id}"]`).parent().addClass('is-active');
        },
        onLeave: (section) => {
            const id = section.id;
            $(`.menu-item a[href="#${id}"]`).parent().removeClass('is-active');
        },
        options: {
            root: null, // Default viewport
            threshold: 0.5, // Trigger when 50% of the section is visible
        },
    });

    // Handle menu trigger click
    $doc.on('click', '.js-menu-trigger', function (event) {
        event.preventDefault();
        $reportNav.toggleClass('is-active');
        $menuTrigger.toggleClass('is-active');

        if ($reportNav.hasClass('is-active')) {
            $menuTrigger.attr('aria-expanded', 'true');
            const scrollY = window.scrollY;
            document.documentElement.style.setProperty('--scroll-y', `${scrollY}px`);
            const body = document.body;
            body.style.position = 'fixed';
            body.style.width = '100%';
            body.style.top = `-${scrollY}px`;
        } else {
            closeReportNav();
        }
    });

    // Close the report nav on escape key press
    $doc.on('keydown', function (event) {
        if ($reportNav.hasClass('is-active') && event.key === 'Escape') {
            closeReportNav();
        }
    });

    // Close the report nav on focus outside
    $doc.on('focusin', function (event) {
        const $target = $(event.target);
        if (!$target.closest('.js-header-wrapper').length && $reportNav.hasClass('is-active')) {
            closeReportNav();
        }
    });

    // Return cleanup function
    return cleanupObserver;
}