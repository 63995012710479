import 'slick-carousel';

import $ from 'jquery'
import initGreenSock from '/lib/greensock'
import initHeadroom from '/lib/headroom'
import initMobileNav from '/lib/mobile-nav'
import initCookies from '/lib/cookies'
import initMagnificPopup from '/lib/magnificPopup'
import initA11yFunctions from '/lib/accessibility'
import initSearch from '/lib/search'
import initStaffFilters from '/lib/staff-filters'
import initScrollTo from '/lib/scroll-to'
import initReportNav from '/lib/report-nav'
import initCapsuleHeadings from './lib/capsule-headings';


initMagnificPopup()
initGreenSock()
initHeadroom()
initMobileNav()
initCookies()
initA11yFunctions()
initSearch()
initStaffFilters()
initScrollTo()
initReportNav()
initCapsuleHeadings()
