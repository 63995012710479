export default function initSectionObserver({
    sectionSelector,
    onEnter,
    onLeave,
    options = { root: null, threshold: 0.5 },
}) {
    if (!sectionSelector || !onEnter || !onLeave) {
        throw new Error("Required parameters: sectionSelector, onEnter, and onLeave.");
    }

    // Cache sections for observation
    const sections = document.querySelectorAll(sectionSelector);

    if (sections.length === 0) {
        console.warn(`No elements found for selector: ${sectionSelector}`);
        return null;
    }

    // Observer callback
    const observerCallback = (entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                onEnter(entry.target);
            } else {
                onLeave(entry.target);
            }
        });
    };

    // Initialize the Intersection Observer
    const observer = new IntersectionObserver(observerCallback, options);

    // Observe each section
    sections.forEach((section) => observer.observe(section));

    // Cleanup function to disconnect observer
    return () => {
        sections.forEach((section) => observer.unobserve(section));
        observer.disconnect();
    };
}